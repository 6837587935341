var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-allowed-capacity',attrs:{"title":_vm.isEditing ? 'Update Office Permission' : 'Create Office Permission',"show":_vm.show,"isCancel":false,"isSubmit":false},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"time off category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Time off category","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"aria-role":"list","placeholder":"Select category...","expanded":true,"disabled":_vm.notUpdatable},model:{value:(_vm.formData.timeOffCategory),callback:function ($$v) {_vm.$set(_vm.formData, "timeOffCategory", $$v)},expression:"formData.timeOffCategory"}},_vm._l((_vm.timeOffTypeList),function(option){return _c('option',{key:'employment-' + option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Start Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Select a date...","min-date":_vm.minDate,"disabled":_vm.notUpdatable},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"end_date","rules":"required|date_higher_than:@start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"End Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Select a date...","min-date":_vm.formData.start_date,"disabled":_vm.notUpdatable},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12 request-section-radio-tooltip"},[(!_vm.isEditing)?_c('div',[_c('ValidationProvider',{attrs:{"name":"additional approver"}},[_c('PICTooltip'),_c('PIC',{attrs:{"isEditing":_vm.isEditing,"listUser":_vm.userList,"isFetchingUser":_vm.isFetchingUser},on:{"getUser":_vm.getUserList,"selectUser":_vm.selectUser,"getMoreUser":_vm.getMoreUserList}})],1)],1):_c('div',[_c('b-field',{attrs:{"label":"PIC"}},[_c('p',[_c('b-icon',{staticClass:"request-approver-icon mr-2",attrs:{"icon":"account"}}),_vm._v(" "+_vm._s(_vm.selectedUser)+" ")],1)])],1)]),(
              _vm.formData.uploadedFiles.length === 0 &&
              _vm.formData.image === null &&
              !_vm.notUpdatable
            )?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Upload File"}},[_c('b-upload',{attrs:{"drag-drop":"","expanded":"","accept":"image/*","disabled":_vm.notUpdatable},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"image","size":"is-large"}})],1),_c('p',[_vm._v(" Drag image here or "),_c('span',{staticClass:"has-red-text"},[_vm._v("Browse")])])])])])],1)],1):_vm._e(),(_vm.formData.uploadedFiles.length > 0)?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"File already uploaded"}},[_c('div',{staticClass:"tags"},_vm._l((_vm.formData.uploadedFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_c('div',{staticClass:"file-tag",on:{"click":function($event){return _vm.openFileUrl(file.url)}}},[_vm._v(" "+_vm._s(file.name)+" ")]),(!_vm.notUpdatable)?_c('div',[_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteUploadedFile({
                          timeOffId: _vm.formData.id,
                          file: file,
                        })}}})]):_vm._e()])}),0)])],1):_vm._e(),(_vm.formData.image != null)?_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"label"},[_vm._v("File to upload")])]):_vm._e(),(_vm.formData.image != null)?_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"tag is-primary"},[_c('div',{staticClass:"file-tag"},[_vm._v(" "+_vm._s(_vm.formData.image.name)+" ")]),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile()}}})])]):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"request reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Request Reason","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Please write your message","disabled":_vm.notUpdatable},model:{value:(_vm.formData.request_reason),callback:function ($$v) {_vm.$set(_vm.formData, "request_reason", $$v)},expression:"formData.request_reason"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"columns container px-2"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button is-light is-fullwidth",on:{"click":function($event){return _vm.hide()}}},[_vm._v(" Cancel ")])],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"native-type":"submit","type":"is-primary","expanded":"","loading":_vm.isUpdating,"disabled":_vm.notUpdatable}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Update' : 'Save')+" ")])],1)])])])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }