<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title has-text-color-page-title">Time Off</h2>
        <r-third-level-menu />
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalEdit"
          :lodaData="isLoadTimeOffList"
          @onSearch="searchIconClick($event)"
        />
      </template>
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12">
            <div id="table-request-timeoff">
              <b-table
                :data="timeOffList"
                :per-page="perPage"
                :show-detail-icon="showDetailIcon"
                ref:table
                narrowed
                backend-sorting
                @sort="onSort"
                sticky-header
              >
                <b-table-column
                  field="id"
                  label="Request ID"
                  v-slot="props"
                  centered
                  sortable
                  numeric
                >
                  <span>
                    {{ props.row.id }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="duration"
                  label="Duration"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.startDate), 'DD/MM/YYYY')
                    }}
                    -
                    {{ formatDate(new Date(props.row.endDate), 'DD/MM/YYYY') }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="request_reason"
                  label="Reason"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      props.row.requestReason ? props.row.requestReason : '-'
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="status"
                  label="Status"
                  v-slot="props"
                  sortable
                >
                  <span
                    :class="`td-time-off-request-status ${determineClassByStatus(
                      props.row.requestStatus
                    )}`"
                  >
                    {{ props.row.requestStatus }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="created_at"
                  label="Created At"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.createdAt), 'DD MMMM YYYY')
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="action"
                  label="Action"
                  centered
                  v-slot="props"
                >
                  <b-icon
                    icon="pencil"
                    custom-size="mdi-18px"
                    @click.native="openModalEdit(props.row)"
                  />
                  <b-icon
                    @click.native="openSideBar(props.row)"
                    icon="eye"
                    custom-size="mdi-18px"
                  />
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-12 has-text-centered" v-if="isLoadMore">
            Please Wait ...
          </div>
        </div>

        <!-- Sidebar to see detail of time off -->
        <!-- fix bug modal edit is not open by checking isRightBarOpen in v-if-->
        <b-sidebar
          v-if="selectedTimeOff != null && isRightBarOpen"
          can-cancel
          type="is-light"
          fullheight
          :fullwidth="false"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Time Off Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeRequestTimeOffDetail"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>

            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Permission Type</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedTimeOff.permissionType }} {{ selectedTimeOff.id }}
              </div>

              <div class="column is-4">Duration Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatDate(new Date(selectedTimeOff.startDate), 'DD/MM/YYYY')
                }}
                -
                {{
                  formatDate(new Date(selectedTimeOff.endDate), 'DD/MM/YYYY')
                }}
              </div>

              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedTimeOff.requestStatus }}
              </div>

              <div class="column is-4" v-if="selectedTimeOff.files.length > 0">
                Files
              </div>
              <div class="column is-8" v-if="selectedTimeOff.files.length > 0">
                <div class="tags">
                  <span
                    v-for="file in selectedTimeOff.files"
                    :key="file.id"
                    class="tag is-primary tag-small"
                  >
                    <div class="file-tag" @click="openFileUrl(file.url)">
                      {{ file.name }}
                    </div>
                  </span>
                </div>
              </div>

              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  selectedTimeOff.requestReason
                    ? selectedTimeOff.requestReason
                    : '-'
                }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedTimeOff.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedTimeOff.requestStatus === 'waiting' ||
                  selectedTimeOff.requestStatus === 'approved'
                "
              >
                <b-button expanded @click="cancelTimeOff(selectedTimeOff.id)">
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <!-- Modal to edit time off -->
        <TimeOffPersonalModal
          :show="isModalEditOpen"
          :formData="data"
          :timeOffTypeList="timeOffTypeList"
          :isEditing="isEditing"
          :isUpdating="isUpdating"
          :userList="userList"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :minDate="minDate"
          :selectedUser="selectedUser"
          @hide="closeModalEdit"
          @submit="updateTimeOff"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
          @openFileUrl="openFileUrl"
          @deleteDropFile="deleteDropFile"
          @deleteUploadedFile="deleteUploadedFile"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  showErrorToast,
  determineFontColorByStatus,
} from '@/services/util'
import ApiService from '@/services/common/api.service'
import TimeOffApprovalHistoryComponent from './TimeOffApprovalHistoryComponent'
import isNotUpdatable from '../Script/isNotUpdatable'
import debounce from 'lodash/debounce'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import TimeOffPersonalModal from './TimeOffPersonalModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    TimeOffPersonalModal,
  },
  computed: {
    ...mapGetters({
      timeOffList: 'timeOffModule/getTimeoffs',
      timeOffTypeList: 'timeOffModule/getTimeOffTypes',
      currentUser: 'auth/currentUser',
      timeOffDetail: 'timeOffModule/getTimeOffDetail',
    }),
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      size: 900,
      isShowModalCreate: false,
      // edit data timeoff
      data: {
        id: '',
        timeOffCategory: null,
        start_date: null,
        end_date: null,
        uploadedFiles: [],
        image: null,
        request_reason: '',
        additionalApproverId: null,
      },
      modalEditKey: 0,

      isUpdating: false,

      minDate: new Date(),
      open: false,
      overlay: true,
      isRightBarOpen: false,
      fullwidth: false,
      right: false,
      isEditing: false,

      isLoadMore: false,
      perPage: 20,
      lastPage: 0,
      total: 0,
      page: 0,

      showDetailIcon: true,
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',
      status: '',
      isLoadTimeOffList: false,

      selectedTimeOff: null,
      isModalEditOpen: false,
      notUpdatable: null,

      searchUser: '',
      pageUserList: 1,
      lastPageUserList: 1,
      selectedUser: '',
      isFetchingUser: false,
      userList: [],
    }
  },
  methods: {
    ...mapActions({
      actionLoadTimeOffList: 'timeOffModule/fetchMyRequestTimeoffs',
      actionCancelTimeOff: 'timeOffModule/cancelTimeoff',
      actionLoadTimeOffTypes: 'timeOffModule/getTimeOffTypes',
      actionUpdateTimeOff: 'timeOffModule/updateTimeoff',
      actionLoadTimeOffDetail: 'timeOffModule/fetchTimeOffDetail',
      actionSaveTimeOff: 'timeOffModule/saveTimeoff',
    }),
    ...mapMutations({
      setTimeOffs: 'timeOffModule/setTimeoffs',
      setTimeOffDetail: 'timeOffModule/setTimeOffDetail',
      removeFileFromTimeOff:
        'timeOffModule/removeSpecificFileFromRequestTimeoff',
    }),

    /**
     * Close Report Modal
     */
    closeModalReport() {
      this.isModalEditOpen = false
      this.resetForm()
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineClassByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setTimeOffs([])
      await this.loadTimeOffList(searchInput)
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Load more data of reimbursement Personal list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadTimeOffList()
        this.isLoadMore = false
      }
    },

    /**
     * Open Sidebar content from table
     * @param {Object} timeOff - selected timeOff data from table row
     * @param {integer} id selected data id
     */
    async openSideBar(timeOff, id) {
      if (id) {
        let response = await this.actionLoadTimeOffDetail(id)
        this.selectedTimeOff = response.data.data
      } else {
        this.selectedTimeOff = timeOff
      }
      this.isRightBarOpen = true
    },

    /**
     * Delete uploaded Image file
     * @param {integer} timeOffId - selected Time Off id
     * @param {Object} file - Image File object
     */
    async deleteUploadedFile(param) {
      const { timeOffId, file } = param
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete File',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure you want to delete ${file.name}? This action cannot be undone.`,
      })

      if (res && res.isConfirmed) {
        try {
          await this.removeFile(timeOffId, file.id)
          showToast('File Deleted', 'is-success', 'is-top')

          this.data.uploadedFiles = this.data.uploadedFiles.filter(
            (f) => f.id != file.id
          )
        } catch (err) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
      } else if (res && res.isDenied) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed',
          confirmButtonText: 'Go Back Home',
          customClass: {
            confirmButton: 'button is-grey',
          },
        })
      }
    },

    /**
     * Remove uploaded File from database using API
     * @param {integer} timeOffId - selected Time Off id
     * @param {integer} fileId - File Id
     */
    async removeFile(timeOffId, fileId) {
      try {
        await ApiService.delete(`/api/space-roketin/files/${fileId}`)
        this.removeFileFromTimeOff({
          timeOffId,
          fileId,
        })
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * Load TimeOff Type dropdown list
     */
    async loadTimeOffTypes() {
      try {
        await this.actionLoadTimeOffTypes()
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * Delete image file
     */
    deleteDropFile() {
      this.data.image = null
    },

    /**
     * Open File on mouse click
     * @param {string} fileUrl - File url from database
     */
    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    /**
     * Submit form data.
     */
    async updateTimeOff() {
      try {
        if (!this.isEditing) {
          this.isUpdating = true
          let formData = new FormData()
          if (this.currentUser !== null) {
            formData.append('user_id', this.currentUser.id)
          }
          if (this.data.image) {
            formData.append('images[]', this.data.image)
          }
          formData.append(
            'start_date',
            moment
              .tz(this.data.start_date, moment.tz.guess(true))
              .format('YYYY-MM-DD')
          )
          formData.append(
            'end_date',
            moment
              .tz(this.data.end_date, moment.tz.guess(true))
              .format('YYYY-MM-DD')
          )
          formData.append('timeoff_type_id', this.data.timeOffCategory)
          formData.append('request_reason', this.data.request_reason)
          formData.append(
            'additional_approver_id',
            this.data.additionalApproverId
          )

          if (!this.data.additionalApproverId) {
            formData.delete('additional_approver_id')
          }

          try {
            await this.actionSaveTimeOff(formData)
            showToast('Add Success', 'is-success', 'is-top')
            this.isModalEditOpen = false
            this.resetState()
            this.resetForm()
          } catch (e) {
            showErrorToast(e)
          }

          this.isUpdating = false
        } else {
          this.isUpdating = true
          let form = new FormData()
          if (this.currentUser !== null) {
            form.append('user_id', this.currentUser.id)
          }
          if (this.data.image) {
            form.append('images[]', this.data.image)
          }
          form.append(
            'start_date',
            moment(this.data.start_date).format('YYYY-MM-DD')
          )
          form.append(
            'end_date',
            moment(this.data.end_date).format('YYYY-MM-DD')
          )
          form.append('timeoff_type_id', this.data.timeOffCategory)
          form.append('request_reason', this.data.request_reason)

          try {
            form.append('_METHOD', 'PUT')
            await this.actionUpdateTimeOff({ form, id: this.data.id })
            showToast('Update Success', 'is-success', 'is-top')
            this.isModalEditOpen = false
            this.resetState()
          } catch (e) {
            showErrorToast(e)
          }
          this.resetForm()
          this.isUpdating = false
        }
      } catch (e) {
        if (e.response?.data?.message) {
          showToast(e.response.data.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * Reset form after modal close
     */
    resetForm() {
      this.data = {
        id: '',
        timeOffCategory: null,
        start_date: null,
        end_date: null,
        image: null,
        uploadedFiles: [],
        request_reason: '',
        additionalApproverId: null,
      }
      this.isEditing = false
      this.searchUser = null
      this.selectedUser = null
    },

    /**
     * Cancel Time Off request
     * @param {integer} id - TimeOff ID
     */
    async cancelTimeOff(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        try {
          await this.actionCancelTimeOff({ id })
          showToast('Request canceled', 'is-success', 'is-top')
          this.isRightBarOpen = false
        } catch (err) {
          console.log(err)
        }
      }
    },

    /**
     * Open Modal Create/ Edit
     * @param {Object} selectedTimeOff - Selected Time Off data from table (Optional)
     */
    openModalEdit(selectedTimeOff) {
      if (selectedTimeOff) {
        this.isEditing = true
        this.data.uploadedFiles = [...selectedTimeOff.files]
        this.data.id = selectedTimeOff.id
        this.data.timeOffCategory = selectedTimeOff.permissionTypeId
        this.data.start_date = new Date(selectedTimeOff.startDate)
        this.data.end_date = new Date(selectedTimeOff.endDate)
        this.data.image = null
        this.data.request_reason = selectedTimeOff.requestReason
        this.selectedUser = selectedTimeOff.history
          ? selectedTimeOff.history[0].fullName
          : '-'

        this.notUpdatable = isNotUpdatable.check(
          selectedTimeOff.requestStatus,
          selectedTimeOff.history
        )
      }

      if (!selectedTimeOff) this.notUpdatable = false
      this.modalEditKey += 1
      this.isModalEditOpen = true
    },

    /**
     * Close Create/ Edit Modal
     */
    closeModalEdit() {
      this.modalEditKey += 1
      this.selectedTimeOff = null

      this.resetForm()
      this.isModalEditOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Close Side bar Time Off request detail
     */
    closeRequestTimeOffDetail() {
      this.isRightBarOpen = false
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @return {dateString} formatted date
     */
    formatDate(date, format) {
      return moment(date).format(format)
    },

    /**
     * Load Time Off list
     * @param {String} searchInput - Keyword search (optional)
     */
    async loadTimeOffList(searchInput) {
      this.isLoadTimeOffList = true
      try {
        let response = await this.actionLoadTimeOffList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
          status: this.status,
        })
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoadTimeOffList = false
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullName
        this.data.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.data.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.userList = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.userList = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUser = true

      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.userList.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),
  },
  async mounted() {
    this.setTimeOffs([])
    await this.loadTimeOffList()
    await this.loadTimeOffTypes()
    if (this.$route.query.id) {
      await this.openSideBar(null, this.$route.query.id)
    }
    window.onresize = () => {
      this.windowHeight = window.innerHeight
    }
  },
}
</script>
