<template>
  <Modal
    :title="isEditing ? 'Update Office Permission' : 'Create Office Permission'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="time off category"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Time off category"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-select
                    aria-role="list"
                    placeholder="Select category..."
                    v-model="formData.timeOffCategory"
                    :expanded="true"
                    :disabled="notUpdatable"
                  >
                    <option
                      v-for="option in timeOffTypeList"
                      :value="option.id"
                      :key="'employment-' + option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="start_date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Start Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="formData.start_date"
                    placeholder="Select a date..."
                    :min-date="minDate"
                    :disabled="notUpdatable"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="end_date"
                v-slot="{ errors }"
                rules="required|date_higher_than:@start_date"
              >
                <b-field
                  label="End Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="formData.end_date"
                    placeholder="Select a date..."
                    :min-date="formData.start_date"
                    :disabled="notUpdatable"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <div v-if="!isEditing">
                <ValidationProvider name="additional approver">
                  <PICTooltip />
                  <PIC
                    :isEditing="isEditing"
                    :listUser="userList"
                    :isFetchingUser="isFetchingUser"
                    @getUser="getUserList"
                    @selectUser="selectUser"
                    @getMoreUser="getMoreUserList"
                  />
                </ValidationProvider>
              </div>
              <div v-else>
                <b-field label="PIC">
                  <p>
                    <b-icon icon="account" class="request-approver-icon mr-2" />
                    {{ selectedUser }}
                  </p>
                </b-field>
              </div>
            </div>
            <div
              class="column is-12"
              v-if="
                formData.uploadedFiles.length === 0 &&
                formData.image === null &&
                !notUpdatable
              "
            >
              <b-field label="Upload File">
                <b-upload
                  v-model="formData.image"
                  drag-drop
                  expanded
                  accept="image/*"
                  :disabled="notUpdatable"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="image" size="is-large"></b-icon>
                      </p>
                      <p>
                        Drag image here or
                        <span class="has-red-text">Browse</span>
                      </p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>

            <div class="column is-12" v-if="formData.uploadedFiles.length > 0">
              <b-field label="File already uploaded">
                <div class="tags">
                  <span
                    v-for="(file, index) in formData.uploadedFiles"
                    :key="index"
                    class="tag is-primary"
                  >
                    <div class="file-tag" @click="openFileUrl(file.url)">
                      {{ file.name }}
                    </div>
                    <div v-if="!notUpdatable">
                      <button
                        class="delete is-small"
                        type="button"
                        @click="
                          deleteUploadedFile({
                            timeOffId: formData.id,
                            file: file,
                          })
                        "
                      ></button>
                    </div>
                  </span>
                </div>
              </b-field>
            </div>

            <div class="column is-4" v-if="formData.image != null">
              <label class="label">File to upload</label>
            </div>
            <div class="column is-4" v-if="formData.image != null">
              <div class="tag is-primary">
                <div class="file-tag">
                  {{ formData.image.name }}
                </div>

                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile()"
                ></button>
              </div>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="request reason"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Request Reason"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    type="textarea"
                    v-model="formData.request_reason"
                    placeholder="Please write your message"
                    :disabled="notUpdatable"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="columns container px-2">
              <div class="column">
                <div class="buttons">
                  <b-button
                    @click="hide()"
                    class="button is-light is-fullwidth"
                  >
                    Cancel
                  </b-button>
                </div>
              </div>

              <div class="column">
                <div class="buttons">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    expanded
                    :loading="isUpdating"
                    :disabled="notUpdatable"
                  >
                    {{ isEditing ? 'Update' : 'Save' }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'
import PICTooltip from '../../../components/PIC/PICTooltip.vue'
import PIC from '../../../components/PIC/PIC.vue'

export default {
  components: { Modal, PICTooltip, PIC },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
    userList: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null,
    },
    selectedUser: {
      type: String,
      default: null,
    },
    timeOffTypeList: {
      type: Array,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit get more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },

    /**
     * Emit open file url.
     */
    openFileUrl(fileUrl) {
      this.$emit('openFileUrl', fileUrl)
    },

    /**
     * Emit delete drop file.
     */
    deleteDropFile() {
      this.$emit('deleteDropFile')
    },

    /**
     * Emit delete uploaded file.
     */
    deleteUploadedFile({ timeOffId, file }) {
      const param = {
        timeOffId,
        file,
      }
      this.$emit('deleteUploadedFile', param)
    },
  },
}
</script>
